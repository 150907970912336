<template>
  <div class="myTeamPage">
    <van-loading type="spinner" v-if="loading" color="#FF006E" vertical>加载中...</van-loading>
    <div v-if="!loading" class="all_content_box">
      <div class="top_box">
        <div class="title">
          <div class="name_info">
            <img class="userImg" :src="userInfo.avatarUrl">
            <div class="info_box">
              <p class="userName">{{ userInfo.nickName }}</p>
              <!-- <div class="grade_and_singout">
                <img v-if="userInfo.userLevel == 'QingMang'" src="../../sprite/QingMang.png" alt="">
                <img v-if="userInfo.userLevel == 'HongMang'" src="../../sprite/HongMang.png" alt="">
                <img v-if="userInfo.userLevel == 'JinMang'" src="../../sprite/jinmang.png" alt="">
                <img v-if="userInfo.userLevel == 'XingMang'" src="../../sprite/XingMang.png" alt="">
              </div> -->
            </div>
          </div>
          <div class="invite_people_btn" v-if="isExist" @click="handleShowInvitePeopleInfo">我的邀请人</div>
        </div>
        <ul class="team_num_box">
          <li>
            <p>{{ teamOverview.total || 0 }}</p>
            <p>团队人数</p>
          </li>
          <li>
            <p>{{ teamOverview.toDayTotal || 0 }}</p>
            <p>今日新增</p>
          </li>
          <li>
            <p>{{ teamOverview.toMonthTotal || 0 }}</p>
            <p>本月新增</p>
          </li>
        </ul>
      </div>
      <div class="invite_log">
        <div class="title">
          <span>邀请记录</span>
        </div>
        <div class="invite_list" v-if="teamList && teamList.length > 0">
          <div class="list_title">
            <span>成员信息</span><span>入队时间</span>
          </div>
          <div class="list_box">
            <van-list v-model="moreLoading" :finished="finished" offset="50" finished-text="没有更多了" @load="upMoreLoad">
              <div class="itemLi" v-for="(item, idx) in teamList" :key="idx">
                <div class="team_info">
                  <img class="userImg" :src="item.avatarUrl">
                  <div class="info_box">
                    <p class="userName">{{ item.nickName }}</p>
                    <!-- <div class="grade_and_singout">
                      <img v-if="item.userLevel == 'QingMang'" src="../../sprite/QingMang.png" alt="">
                      <img v-if="item.userLevel == 'HongMang'" src="../../sprite/HongMang.png" alt="">
                      <img v-if="item.userLevel == 'JinMang'" src="../../sprite/jinmang.png" alt="">
                      <img v-if="item.userLevel == 'XingMang'" src="../../sprite/XingMang.png" alt="">
                    </div> -->
                  </div>
                </div>
                <div class="join_time">{{ item.registerTime }}</div>
              </div>
            </van-list>
          </div>
        </div>
        <div class="no_data" v-if="teamList.length === 0">
          <img src="../../sprite/myTeam_no_data.png" alt="">
          <p>您的团队还是空的哦，快去邀请吧~</p>
        </div>
      </div>
      <div class="invite_friends_btn" @click="showInvite"><span>邀请好友</span></div>
    </div>
    <!-- 邀请人信息 popup -->
    <van-popup v-model="showInvitePeopleInfo" :closeable="false" :round="true" :close-on-click-overlay="false" overlay
      class="invitePeopleInfo">
      <div class="people_info_box">
        <div class="invite_avatarUrl">
          <img :src="superiorInfo.avatarUrl" alt="" />
        </div>
        <!-- <img class="user_level_icon" v-if="superiorInfo.userLevel == 'QingMang'" src="../../sprite/QingMang.png" alt="">
        <img class="user_level_icon" v-if="superiorInfo.userLevel == 'HongMang'" src="../../sprite/HongMang.png" alt="">
        <img class="user_level_icon" v-if="superiorInfo.userLevel == 'JinMang'" src="../../sprite/jinmang.png" alt="">
        <img class="user_level_icon" v-if="superiorInfo.userLevel == 'XingMang'" src="../../sprite/XingMang.png" alt=""> -->
        <p class="user_name">{{ superiorInfo.nickName }}</p>
        <p class="invite_time">邀请时间：{{ superiorInfo.registerTime }}</p>
        <div class="invite_peo">我的邀请人</div>
      </div>
      <van-icon name="close" size="0.66667rem" color="#fff" class="iconClose" @click="showInvitePeopleInfo = false" />
    </van-popup>
    <!-- 邀请好友海报 -->
    <InviteFriendsPopup v-if="showInviteFriendsPopup" :showPosterInvite="showInviteFriendsPopup"
      :superiorUrlInvite="superiorUrlInvite" pageType="myTeam" @closePopup="handleClose"></InviteFriendsPopup>
  </div>
</template>
<script>
import { Dialog, Popup } from 'vant'
import './index.less'
import { isMangguoApp } from '@/utils/tokenUtils'
import { GetTeamOverview, GetTeamList, GetSuperiorInfo } from '@/service/my'
import { mapState, mapActions } from 'vuex'
import html2canvas from 'html2canvas'
import QRCode from 'qrcode'
export default {
  components: {
    vanDialog: Dialog,
    VanPopup: Popup
  },
  data() {
    return {
      isApp: isMangguoApp(),
      loading: false,
      teamOverview: {}, // 团队统计数据
      superiorInfo: {}, // 我的邀请人信息
      showInvitePeopleInfo: false, // 邀请人信息 popup 显隐
      isExist: false, // 邀请人是否存在
      teamList: [], // 团队成员信息
      pageIndex: 1,
      pageSize: 10,
      moreLoading: false,
      finished: false,
      welfareShow: false, // 控制 “加入福利群” 显隐

      superiorUrlInvite: '', // 存储 邀请好友链接
      showInviteFriendsPopup: false // 邀请海报显隐
    }
  },
  computed: {
    ...mapState({
      isDisabled: (state) => state.isDisabled,
      userInfo: (state) => state.userInfo,
      userAccountInfo: (state) => state.userAccountInfo
    })
  },
  created() { },
  mounted() {
    this.init();
  },
  methods: {
    ...mapActions({
      getUserInfo: 'saveUserInfo',
      getUserAccountInfo: 'saveUserAccountInfo'
    }),
    async init() {
      this.loading = true
      await this.getUserInfo() // 盟客基础信息
      await this.getUserAccountInfo() // 盟客账户信息
      await this.getTeamOverview() // 团队统计
      await this.getSuperiorInfo() // 我的邀请人
      await this.upMoreLoad() // 我的团队
      setTimeout(() => {
        // 保存邀请好友的分享链接（以备后用）
        let url = window.location.href.split('#')[0]
        this.superiorUrlInvite =
          process.env.NODE_ENV == 'development'
            ? `http://test.youku.com/tpl/mclub/#/shareExtension?nickName=${encodeURIComponent(
              this.userInfo.nickName
            )}&superiorId=${this.userInfo.id}`
            : `${url}#/shareExtension?nickName=${encodeURIComponent(
              this.userInfo.nickName
            )}&superiorId=${this.userInfo.id}`
        this.loading = false
      }, 200)
    },
    // 团队统计
    getTeamOverview() {
      GetTeamOverview().then((res) => {
        const { code, data, message } = res
        if (code == '0') {
          this.teamOverview = data || {}
        } else {
          this.$toast(message)
        }
      })
    },
    // 我的邀请人
    getSuperiorInfo() {
      GetSuperiorInfo().then((res) => {
        const { code, data, message } = res
        if (code == '0') {
          this.superiorInfo = data || {}
          if (data) this.isExist = true
        } else if (code == '-1') {
          this.isExist = false // 邀请人不存在
        } else {
          this.$toast(message)
        }
      })
    },
    handleShowInvitePeopleInfo() {
      this.showInvitePeopleInfo = true
    },
    // 我的团队
    getTeamList() {
      const idx = this.pageIndex
      GetTeamList({
        pageIndex: this.pageIndex,
        pageSize: this.pageSize
      }).then((res) => {
        const { code, data, message } = res
        if (code == '0') {
          this.moreLoading = false
          if (idx > 1) {
            this.teamList = [...this.teamList, ...data.list]
          } else {
            this.teamList = data.list
          }
          if (data && this.teamList.length == data.total) {
            this.finished = true
          } else {
            this.finished = false
          }
        } else {
          this.$toast(message)
        }
      })
    },
    // 上滑加载更多
    upMoreLoad() {
      this.moreLoading = true
      this.getTeamList()
      this.pageIndex += 1
    },

    // 点击 邀好友
    showInvite() {
      this.showInviteFriendsPopup = true
    },
    handleClose() {
      this.showInviteFriendsPopup = false
    }
  }
}
</script>
<style lang="less" scoped></style>